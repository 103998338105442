/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'cbfont';
  src: url('/font/cbfont.eot');
  src: url('/font/cbfont.eot?#iefix') format('embedded-opentype'), url("/font/cbfont.woff") format('woff'), url("/font/cbfont.ttf") format('truetype'), url("/font/cbfont.svg#cbfont") format('svg');
}
@font-face {
  font-family: "FuturaBook";
  src: url("/extras/fonts/font.woff2") format("woff2"), url("/extras/fonts/font.woff") format("woff");
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2013-1-16 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.area {
  min-height: 280px;
}
.unit {
  margin-top: 35px;
  margin-bottom: 35px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  min-height: 2em;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #8c8c8c;
  color: #8c8c8c;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #4c4c4c !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-twitter ul {
  list-style-type: none;
}
.cb-twitter li {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #8c8c8c;
}
.cb-twittercontainer span {
  font-size: 0.95em;
}
.cb-twitterfeed {
  display: block;
  padding: 5px;
  background-color: #eee;
}
.cb-statistics .cb-loading,
.cb-twittercontainer .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url(/icons/email.png) no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url(/icons/delicious.png);
}
.cb-service > .cb-digg {
  background-image: url(/icons/digg.png);
}
.cb-service > .cb-twitter {
  background-image: url(/icons/twitter.png);
}
.cb-service > .cb-facebook {
  background-image: url(/icons/facebook.png);
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
/* .cb-share .cb-service-facebook-like,
.cb-share .cb-service-facebook-recommend {
  margin-bottom: 12px; */
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #666666;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td .vert .trim th {
  border-width: 0 1px;
}
.grid thead td,
.grid thead th,
.grid tfoot td {
  background: #999;
  font-weight: bold;
}
.grid th {
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #cccccc;
}
a.plug {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url(/objects/flashplayer/images/adobe.png) no-repeat 50%;
}
#root div.foto img.cb-loading,
#root div.foto div.cb-loading,
#root #disp img.cb-loading {
  overflow: hidden;
  outline: 1px dashed #eee;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: url(/images/over.png);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #4c4c4c;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  color: rgba(255, 255, 255, 0.92);
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url(/images/lb-ctr.png) no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail {
  top: 15% !important;
}
#disp.mail .body {
  padding: 15px;
}
#disp.mail .head {
  top: -80px;
  left: 0;
}
#disp.mail .foot .submit {
  margin-right: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot,
#disp.cb-lightbox-code .foot,
#disp.message .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 4.4em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #4c4c4c;
}
.cb-disp-sent {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
}
#disp fieldset {
  padding: 0.2em 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  display: block;
  width: 100%;
  font-weight: bold;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #4c4c4c;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  margin-bottom: 1em;
  padding-bottom: 5px;
  color: #e64246;
  text-overflow: ellipsis;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url(/images/list-sitemap.png);
}
#disp.srch a,
#disp.site li > a,
div.cb-sitemap a {
  display: block;
  color: #4c4c4c;
  text-decoration: none;
}
#disp.srch a:hover,
#disp.site li > a:hover,
div.cb-sitemap a:hover,
#disp.srch a:focus,
#disp.site li > a:focus,
div.cb-sitemap a:focus {
  text-decoration: underline;
}
/*#disp.site h2 {
  letter-spacing: normal; // german version "Die Seite wurde nicht gefunden" has to fit in head
  .font-size(18);
}*/
/* DISP SEARCH */
#disp.srch th,
#disp.srch td {
  padding: 0 2px;
  vertical-align: top;
  text-align: left;
}
#disp.srch th:first-child {
  text-align: right;
}
#disp.srch td {
  /* overwrite default */
  padding-bottom: 0.8em;
}
.flag {
  background-color: #ffffff;
  color: #fd0;
  text-shadow: none;
}
::-moz-selection {
  background-color: #eeeeef;
  color: #3b3b3d;
  text-shadow: none;
}
::selection {
  background-color: #eeeeef;
  color: #3b3b3d;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #6d6e71;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #6d6e71;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  height: 100%;
}
html {
  background: #fff;
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(109, 110, 113, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  background-color: transparent;
  color: #4c4c4c;
  font-family: 'FuturaBook', helvetica, arial, sans-serif;
  line-height: 1.5em;
}
#desk {
  margin: 0 auto;
  width: 100%;
  *zoom: 1;
}
#desk:before,
#desk:after {
  display: table;
  content: '';
}
#desk:after {
  clear: both;
}
#expo {
  position: relative;
}
#expo div.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#view.cb-layout4 #head {
  display: none;
}
#disp {
  z-index: 2500;
}
#over {
  z-index: 2400;
}
.toplink {
  position: fixed;
  z-index: 1900;
  bottom: 50px;
  right: 0;
  transform: translateX(100%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-size: 12px 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/toplink.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  background-color: #fff;
  border: 1px solid #cccccc;
}
.toplink.cb-goto-top-visible {
  right: 50px;
  transform: translateX(0);
}
@media (max-width: 1239px) {
  .toplink {
    bottom: 20px;
    width: 30px;
    height: 30px;
    background-size: 9px 18px;
  }
  .toplink.cb-goto-top-visible {
    right: 20px;
    bottom: 60px;
  }
}
#header {
  float: left;
  width: 100%;
}
#content {
  width: 100%;
  float: left;
}
#mood {
  float: left;
  width: 100%;
}
.cb-layout2 #mood,
.cb-layout3 #mood {
  display: none;
}
#maincontent {
  float: left;
  width: 100%;
}
#footer {
  float: left;
  width: 100%;
}
#root div.pict {
  max-width: 100%;
  height: auto;
}
#root #view div.area {
  min-height: 0;
}
.top {
  float: right;
  margin-top: 30px;
  font-size: 16px;
  color: #b2b2b2;
}
.top:hover,
.top:focus {
  color: #4C4C4C;
}
/* VCARD */
.vcard {
  float: left;
  color: #b2b2b2;
  line-height: 50px;
}
.vcard a {
  color: #b2b2b2;
}
div.cb-twitter iframe {
  width: 100% !important;
}
div.cb-youtube iframe {
  width: 100%;
  height: 100%;
}
.specialdesk {
  float: left;
  width: 100%;
}
.cb-layout4 .specialdesk {
  float: none;
  margin: 0 auto;
  max-width: 1200px;
  *zoom: 1;
}
.cb-layout4 .specialdesk:before,
.cb-layout4 .specialdesk:after {
  display: table;
  content: '';
}
.cb-layout4 .specialdesk:after {
  clear: both;
}
#social {
  float: right;
  display: block;
}
#social .meta {
  float: left;
  width: 50px;
  height: 50px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(/images/social-facebook.svg);
  margin-right: 24px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_instagram {
  background-image: url(/images/social-instagram.svg);
}
#social .meta.service_youtube {
  width: 86px;
  background-image: url(/images/social-youtube.svg);
}
#social .meta.service_linkedin {
  background-image: url(/images/social-linkedin.svg);
}
#social .meta.service_twitter {
  background-image: url(/images/social-twitter.svg);
}
#cmsbox {
  float: left;
  width: 100%;
  display: block;
  text-align: center;
  color: #b2b2b2;
  margin-top: 26px;
  margin-bottom: 50px;
}
#cmsbox .meta {
  color: #b2b2b2;
}
.section {
  float: left;
  width: 100%;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #fff;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1239px) {
  .section--header {
    position: relative;
    border: none;
  }
}
.section--header.cb-scroll-triggered--active {
  border-color: #cccccc;
}
.header__desk {
  float: left;
  width: 1200px;
  max-width: 96%;
}
.cb-layout4 .header__desk {
  width: 2400px;
  max-width: 94%;
}
@media (max-width: 1239px) {
  .header__desk {
    min-height: 80px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 544px) {
  .header__desk {
    min-height: 60px;
  }
}
#home {
  float: left;
  height: 24px;
  margin: 50px 0;
}
@media (max-width: 1239px) {
  #home {
    margin: 50px 0 0;
    width: calc(100% - 80px);
    max-width: 506px;
    height: auto;
    margin: 0;
  }
}
.logo {
  float: left;
  height: 100%;
  width: auto;
}
@media (max-width: 544px) {
  .logo {
    width: 100%;
    height: auto;
  }
}
.navigation {
  float: right;
  margin: 50px 0;
}
@media (max-width: 1239px) {
  .navigation {
    display: none;
  }
}
@media (min-width: 1240px) {
  #home {
    transition: all 0.4s;
  }
  .cb-scroll-triggered--active #home {
    height: 20px;
    margin: 20px 0;
  }
  .navigation {
    transition: all 0.4s;
  }
  .cb-scroll-triggered--active .navigation {
    margin: 18px 0;
  }
}
#over {
  background: #fff;
}
#disp.zoom {
  top: 0 !important;
  padding: 0 !important;
  display: block !important;
}
#disp.zoom .disp-wrapper {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#disp.zoom div.head {
  position: static;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  left: 0;
  top: 0;
  right: unset;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#disp.zoom div.head div.ctrl a.cb-hybrid {
  position: absolute;
  left: 4%;
  top: 50%;
  margin-top: -30px;
  width: 50px;
  height: 60px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/prev-2019-03-18.svg);
}
#disp.zoom div.head div.ctrl .next a.cb-hybrid {
  left: unset;
  right: 4%;
  background-image: url(/images/next-2019-03-18.svg);
}
#disp.zoom div.head div.ctrl a.quit {
  position: absolute;
  right: 4%;
  top: 4vw;
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/close-2019-03-18.svg);
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl a.cb-hybrid {
    width: 25px;
    height: 30px;
    margin-top: -15px;
  }
  #disp.zoom div.head div.ctrl a.quit {
    width: 30px;
    height: 30px;
  }
}
#disp.zoom div.body {
  margin: 4vw 0;
  padding: 0;
  width: 92%;
  border-radius: 0;
}
#disp.zoom div.body > div {
  width: 100% !important;
}
#disp.zoom div.foot {
  display: none;
}
#root #over {
  height: 100% !important;
}
#root #disp.zoom {
  position: fixed;
  height: 100%;
}
#root #disp.zoom div.head div.ctrl a.cb-hybrid {
  left: 2vw;
}
#root #disp.zoom div.head div.ctrl .next a.cb-hybrid {
  left: auto;
  right: 2vw;
}
#root #disp.zoom div.head a.quit {
  right: 2vw;
  top: 2vw;
}
#root #disp.zoom div.body {
  height: 100%;
  width: 100vw;
  margin: 0;
  padding: 2vw;
  box-sizing: border-box;
}
#root #disp.zoom div.body > div {
  height: 100%;
}
#root #disp.zoom div.body > div .cb-zoom-image {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  object-position: center;
  background: none !important;
}
.disp-wrapper {
  height: 100%;
}
h1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;
  color: #000;
  text-transform: uppercase;
}
.cb-layout2 div.main div.head {
  margin-top: 11px;
}
.unit h2 {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;
  color: #000;
  text-transform: uppercase;
}
.cb-layout4 .unit h2 {
  color: #6f6f6e;
}
.flat h2 {
  text-transform: none;
}
h3,
h4,
h5,
h6 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}
h6 {
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
}
#view.cb-layout3 h6 {
  display: none;
}
#view.cb-layout3 div.lead {
  margin: 0;
}
strong {
  font-weight: bold;
}
.loud {
  padding: 0.2em 0.4em;
  background-color: #4c4c4c;
  color: #fff;
  /* redo with @basecolor & guard */
}
.loud > .open {
  color: #e6e6e6;
  /* redo with @basecolor */
}
.loud > .open:hover,
.loud > .open:focus {
  color: #bfbfbf;
}
.pale {
  color: #999999;
  /* color-variable */
  font-size: 0.9em;
}
.pale > .open {
  color: #7f7f7f;
}
.pale > .open:hover,
.pale > .open:focus {
  color: #666666;
}
.skew {
  letter-spacing: 0.03em;
  font-style: italic;
}
.gist,
.dent {
  list-style-image: url(/icon-link/list.gif);
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #4c4c4c;
}
a:focus {
  color: #000;
}
.link {
  background: url(/icon-link/link.gif) no-repeat 0 0.3em;
}
.link.mail {
  background-image: url(/icon-link/mail.gif);
  background-position: 0 0.4em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url(/icon-link/rss_small.gif);
  background-position: 0 0.4em;
}
div.link.mail {
  background: none !important;
}
div.link.mail a.open {
  margin-left: 0;
}
#edit.cb-layout1 div.main {
  border-right: 3px dashed #ccc;
}
#edit.cb-layout1 div.base {
  border-top: 3px dashed #ccc;
}
div.main {
  display: flex;
  flex-wrap: wrap;
}
.cb-layout2 div.unit div.head,
.cb-layout3 div.unit div.head {
  margin-bottom: 9px !important;
}
.cb-layout2 div.unit div.part,
.cb-layout3 div.unit div.part {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}
#root div.main div.form div.part {
  margin-top: 22px;
  margin-bottom: 21px;
}
.cb-layout4 div.main {
  font-size: 16px;
  line-height: 1.375;
  color: #6f6f6e;
}
.cb-layout4 div.main div.unit {
  margin-top: 35px;
  margin-bottom: 35px;
}
.cb-layout4 div.main div.unit div.part.pict {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout4 div.main div.unit div.part.pict img {
  transition: all 0.3s;
}
.cb-layout4 div.main div.unit div.part.pict a {
  background: #fff;
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
}
.cb-layout4 div.main div.unit div.part.pict a:after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 1.16;
  letter-spacing: 0.05em;
  transition: all 0.3s;
  transform: scale(0.9);
  opacity: 0;
}
.cb-layout4 div.main div.unit div.part.pict a:hover img,
.cb-layout4 div.main div.unit div.part.pict a:focus img {
  opacity: 0.5;
}
.cb-layout4 div.main div.unit div.part.pict a:hover:after,
.cb-layout4 div.main div.unit div.part.pict a:focus:after {
  transform: scale(1);
  opacity: 1;
}
div.cb-album.cb-matrix .body-mobile {
  display: none !important;
}
div.cb-album.cb-matrix .body-non-mobile {
  display: block !important;
}
div.cb-album.cb-matrix .body-non-mobile div.part.foto {
  height: auto !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
@media (max-width: 1239px) {
  div.cb-album.cb-matrix .body-non-mobile div.part.foto {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}
div.cb-album.cb-matrix .body-non-mobile div.part.foto > a,
div.cb-album.cb-matrix .body-non-mobile div.part.foto > span {
  position: relative;
  height: 0;
  padding-bottom: 66.66666667%;
}
div.cb-album.cb-matrix .body-non-mobile div.part.foto > a img:first-child,
div.cb-album.cb-matrix .body-non-mobile div.part.foto > span img:first-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
div.cb-album.cb-matrix.seam .body-non-mobile div.part.foto > a,
div.cb-album.cb-matrix.seam .body-non-mobile div.part.foto > span {
  padding-bottom: 133.1838565%;
}
.cb-mobile-invisible .beta span > .cb-hybrid,
.cb-mobile-invisible .days td > .cb-hybrid,
.cb-mobile-invisible .week td > .cb-hybrid,
.cb-mobile-invisible .year td > .cb-hybrid,
.cb-mobile-invisible .cats li > .cb-hybrid {
  text-decoration: underline;
}
/* PICT TITLE as Caption */
#view.cb-layout3 .seam .pict {
  /* hacks müssen noch gemacht werden */
  background: #000;
  position: relative;
}
#view.cb-layout3 .seam .pict a {
  text-decoration: none;
}
#view.cb-layout3 .seam .pict > a:after {
  left: 0;
  width: 90%;
  bottom: 1em;
  color: #fff;
  padding: 0 5%;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  line-height: 1.2em;
  content: attr(title);
  text-indent: -9000px;
}
#view.cb-layout3 .seam .pict > a:hover:after,
#view.cb-layout3 .seam .pict > a:focus:after {
  text-indent: 0;
  text-align: center;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.7), 0 1px 3px rgba(0, 0, 0, 0.6);
}
#view.cb-layout3 .seam .pict > a > img {
  float: left;
  border-radius: 1px;
  -o-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
}
#view.cb-layout3 .seam .pict > a:hover > img {
  opacity: 0.6;
  /* hacks */
  -o-transition: opacity 0.218s;
  -moz-transition: opacity 0.218s;
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  -webkit-filter: grayscale 0;
}
/* unit edge.wide is layout helper */
#content .main .edge.wide {
  width: 100%;
}
#edit #content .main .edge.wide {
  color: #ccc;
  position: relative;
  border-top: 2px dashed #ddd;
}
#edit #content .main .edge.wide:after {
  top: 10px;
  left: 10px;
  color: #444;
  font-size: 11px;
  background: #eee;
  position: absolute;
  border-radius: 3px;
  padding: 0 0.5em 1px;
  letter-spacing: 0.03em;
  content: 'Layout-Helper';
  text-shadow: 0 1px 0 #fff;
  font-family: 'Helvetica Neue', sans-serif;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3), 0 0 0 1px #ddd inset, 0 0 0 2px #fff inset;
}
#edit #content .main .edge.wide h2 {
  color: #ccc;
}
#content .main div.edge.wide,
#content .main div.edge.wide div.body {
  margin-top: 0;
  min-height: 0;
  margin-bottom: 0;
}
/* @end */
/* MOOD NAVIGATION */
#expo {
  overflow: hidden;
}
#expo div.link {
  top: 0;
  width: 100%;
  position: absolute;
  background-image: none;
  z-index: 2;
}
#root #expo .link {
  background: none;
}
#expo div.link a.link {
  width: 50%;
  color: #777;
  height: 100%;
  position: absolute;
  text-align: center;
  text-indent: -99999px;
  background: transparent url(/icon-mood/transparent.png) repeat 0 0;
  z-index: 2;
}
#expo div.link a.link:hover,
#expo div.link a.link:focus {
  text-decoration: none;
}
#expo div.link a.prev {
  left: 0;
}
#expo div.link a.next {
  right: 0;
}
#root #expo div.link a.prev:hover,
#root #expo div.link a.prev:focus {
  background: transparent url(/images/prev-2019-03-18.svg) no-repeat 0 50%;
}
#root #expo div.link a.next:hover,
#root #expo div.link a.next:focus {
  background: transparent url(/images/next-2019-03-18.svg) no-repeat right 50%;
}
/* INDEX TABLE */
#expo .cb-index {
  bottom: -50px;
  right: 0;
  position: absolute;
  border-collapse: collapse;
  z-index: 3;
}
.cb-layout4 #expo .cb-index {
  display: none;
}
#expo .cb-index .cb-index-some {
  display: none;
}
#expo .cb-index td {
  text-align: center;
}
#expo .cb-index td a {
  color: #777;
  width: 12px;
  height: 12px;
  float: right;
  margin-left: 10px;
  display: block;
  line-height: 20px;
  text-indent: 100%;
  overflow: hidden;
}
#root #expo div.link .cb-index td.this a,
#root #expo div.link .cb-index td a:hover,
#root #expo div.link .cb-index td a:focus {
  text-decoration: none;
  background-color: #868686;
}
#root #expo div.link .cb-index td a:active {
  background-color: #cfd2d7;
}
#expo div.link .cb-index td.even a,
#expo div.link .cb-index td.odd a {
  background-color: #e8e8e8;
  border-radius: 10px;
}
/******* forms.less 2013-1-16 *******/
fieldset {
  line-height: 1.3em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  padding-top: 0;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input,
textarea {
  color: #4c4c4c;
  background-color: #fff;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.13333333em 3px;
  min-height: 1.3em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 12px;
  font-size: 1.2rem;
  color: #444;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
input.hint {
  color: #8c8c8c;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
input.submit,
button.cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #4c4c4c;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  line-height: 1.3em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #b3b3b3 #999999 #999999 #b3b3b3;
  background-color: #d4d4d4;
  color: #3d3d3d;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #8c8c8c #a6a6a6 #a6a6a6 #8c8c8c;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form textarea {
  overflow: auto;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.form .ship .chop {
  overflow: visible;
}
.ship .chop > a {
  margin-left: 8px;
}
.chop > .file {
  float: right;
  width: 100%;
}
input.radio,
input.checkbox {
  margin: -3px 0.3em 0 0;
  min-height: 1.3em;
  vertical-align: middle;
}
.tick input.radio,
.tick input.checkbox {
  float: left;
  margin-top: 0;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
  padding-bottom: 0.2em;
}
.form .ctrl .sign {
  display: block;
  margin-left: 20px;
}
div.cb-form-sent {
  padding: 4px 0;
  border: 1px #218823;
  border-style: solid none;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 30px !important;
}
div.fail {
  background: #fef6f6 url(/icons/warning.png) no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url(/icons/warning.png) no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
input.fail,
textarea.fail {
  background: #fef6f6 url(/icons/warning.png) no-repeat 99% !important;
  border-color: #e64246;
  color: #e64246;
}
.fail .sign {
  width: 90%;
}
/* input.fade,
select.fade,
textarea.fade { // if !important added: fix shop input.payp.fade
    &,
    &:hover,
    &:focus,
    &:active {
    border-color: lighten(@input-border,25); */
/* background-color: @lightergray; */
/* background-image: none; */
/* color: lighten(@input-color, 35); */
@media (max-width: 1239px) {
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 23px;
  right: 2%;
  z-index: 2002;
  width: 52px;
  height: 34px;
  cursor: pointer;
  background-color: #fff;
  display: none;
}
@media (max-width: 1239px) {
  .togglenavigation {
    display: block;
  }
}
@media (max-width: 544px) {
  .togglenavigation {
    top: 13px;
  }
}
.tline {
  position: absolute;
  top: 7px;
  left: 9px;
  width: 34px;
  height: 4px;
  background-color: #6d6e71;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 7px;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateX(-20px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateX(20px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2001;
  height: 100%;
  width: 100%;
  max-width: 280px;
  background-color: #fff;
  overflow-y: scroll;
  display: none;
  transform: translateX(100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 1239px) {
  .mobile-navigation {
    display: block;
  }
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateX(0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #fff;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  margin: 88px 0 30px;
  background-color: #999a9c;
}
.mobile-navigation div.sub1 > .item {
  border-top: 1px solid #fff;
}
.mobile-navigation div.sub1 > .item.init {
  border: none;
}
.mobile-navigation div.sub1 > .item.path {
  background-color: #6d6e71;
}
.mobile-navigation div.sub1 > .item > .menu {
  padding: 10px 12px;
  padding-right: calc(2vw + 52px);
  font-size: 14px;
  line-height: 1.14285714;
  text-transform: uppercase;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-size: 14px;
  line-height: 1.14285714;
  padding: 10px 12px 10px 20px;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 2vw;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 52px;
  height: 36px;
  background-size: 6px 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/mnavitoggle.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(90deg);
}
/******* layout-small.less 2013-1-16 *******/
#desk {
  width: 100%;
}
#head {
  margin-top: 15px;
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
#slides,
#expo img {
  width: 100%;
  height: auto;
}
#expo {
  width: 96%;
  height: auto;
}
.cb-layout4 #expo {
  height: 0;
  padding-bottom: 66.66666667%;
}
#expo .cb-index {
  display: none;
}
.slide {
  width: 100%;
  height: auto;
}
#expo {
  margin-top: 28px;
  margin-left: 2%;
  margin-right: 2%;
}
#root #expo div.link a.prev:hover,
#root #expo div.link a.prev:focus {
  background: none;
}
#root #expo div.link a.next:hover,
#root #expo div.link a.next:focus {
  background: none;
}
@media (max-width: 1199px) {
  .vcard {
    width: 100%;
    text-align: center;
    line-height: 1.6;
  }
  .vcard .org,
  .vcard .adr,
  .vcard .street-address {
    display: block;
  }
  #social {
    margin-top: 26px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #social .meta {
    width: 26px;
    height: 26px;
    background-size: 100% 100%;
  }
  #social .meta.service_youtube {
    width: 60px;
  }
}
#footer {
  margin-top: 30px;
  width: 96%;
  margin: 30px 2%;
}
#view.cb-layout3 .seam .pict > a:after {
  text-indent: 0;
  text-align: center;
}
.top {
  margin-right: 2%;
}
.cb-layout4 #content {
  margin-top: 20px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-layout3 div.main div.unit .part,
.cb-layout3 div.main div.unit .grid table {
  margin-top: 1%;
  margin-bottom: 1%;
}
div.side div.unit div.body,
div.base div.unit div.body,
div.main div.unit div.body {
  margin-bottom: 0;
  margin-top: 0;
}
@media (max-width: 544px) {
  div.unit.cb-matrix div.part {
    width: 96% !important;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 3% !important;
}
#disp.zoom {
  left: 0%;
  margin-left: 0;
  width: 100%;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*#disp.cb-photoeditor {
  .disp(90); 
}*/
/*#disp.cb-lightbox-code {
  .disp(90);
}*/
/*#disp.cb-lightbox-code textarea {
  width: 428px;
}*/
/*#disp.shop,
#disp.cb-shoppingcart {
   .disp(90);
   min-width: 340px;
}*/
h1,
h2 {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
/* scan.less 2013-1-16 */
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
}
.swap td > span,
.swap td > .cb-hybrid {
  display: block;
}
td.prev,
td.next {
  color: rgba(0, 0, 0, 0.3);
}
.unit.cb-batch .same {
  background-color: #d4d5d6;
  color: #4c4c4c;
}
/******* module-album.less 2013-1-16 *******/
.body-mobile img,
noscript img {
  /* resize - noscript mode must still work */
  width: 100%;
}
div.foto,
.crossslideContainer {
  position: relative;
}
.crossslide,
.cb-slideshow-images img {
  width: 100%;
}
.cb-strips .foto > img,
.cb-matrix .body-mobile .foto > img,
.cb-contentflow .foto > img,
.cb-slideshow .foto > img,
.body-non-mobile .foto > img {
  margin: 0 auto;
  width: 100%;
}
.body-mobile a.foto,
.body-mobile span.foto {
  width: 100%;
}
noscript a.foto {
  position: relative !important;
}
.body-mobile .cb-matrix .foto,
.body-mobile .cb-contentflow .foto {
  position: relative;
}
.part.desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.same.norm,
.same.foto {
  background: none;
}
.body-non-mobile a.foto,
.body-non-mobile span.foto {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 0;
  width: 100%;
}
.cb-strips table {
  border-collapse: collapse;
}
td > .body {
  display: inline-block;
  vertical-align: middle;
}
.cb-strips-images .foto {
  position: absolute;
}
.cb-strips-images img {
  width: 100%;
}
.cb-contentflow .body-non-mobile .part {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}
.foto > .head {
  position: relative;
}
.cb-manual .desc,
.body-non-mobile > .desc {
  position: relative;
}
#view .cb-manual .desc .norm,
#view .body-non-mobile > .desc .norm {
  position: absolute;
  top: 0;
  left: 0;
}
.foto > .head > .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
}
.cb-album .ctrl {
  float: right;
}
#root div.foto div.body div.desc {
  float: left;
}
#root div.foto div.body div.desc div.part {
  float: none;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #4c4c4c;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  line-height: 1.3em;
  float: left;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #b3b3b3 #999999 #999999 #b3b3b3;
  background-color: #d4d4d4;
  color: #3d3d3d;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #8c8c8c #a6a6a6 #a6a6a6 #8c8c8c;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/******* module-filter.less 2013-1-16 *******/
.cb-count {
  padding: 0 !important;
}
.scan th {
  text-align: center;
  white-space: nowrap;
}
.tabs td,
.time td,
.beta td {
  text-align: center;
}
.mese td,
.mese tbody th {
  text-align: right;
}
.mese td > .cb-hybrid {
  display: block;
}
.cats span {
  padding: 0;
}
.scan > p {
  font-size: 0;
  text-align: center;
}
.scan > p > span {
  padding: 0;
}
.scan > p > .same,
.scan > p > span > .cb-hybrid {
  padding: 0 0.3em;
}
p > span > .cb-hybrid {
  display: inline-block;
}
p > .same,
p > span > .same {
  display: inline-block;
  background: none !important;
}
/******* module-filter-small.less 2013-1-16 *******/
.cb-mobile-filter {
  z-index: 20;
  display: block;
  width: 100%;
  background: #1e1e1e;
  color: #555;
  font-size: 18px;
  font-size: 1.8rem;
}
.cb-mobile-filter-link {
  width: inherit;
}
.cb-icon-filter:before {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  font-family: 'cbfont';
  line-height: 1em;
  speak: none;
  padding: 0 0.2em 0 0.6em;
  width: 1.2em;
  content: '\f0c9';
}
.cb-mobile-filter-items {
  padding-bottom: 1em;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
.cb-mobile-filter-items .cb-hybrid {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  color: #e6e6e6;
  -webkit-tap-highlight-color: rgba(200, 200, 200, 0.4);
}
.cb-mobile-filter-items .cb-hybrid:hover,
.cb-mobile-filter-items .cb-hybrid:focus,
.cb-mobile-filter-items .cb-hybrid:active {
  background: #272727;
  color: #fafafa;
  text-decoration: none;
}
.cb-mobile-filter-items .mese td > span,
.cb-mobile-filter-items li > span {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.cb-mobile-filter-items > .cb-filter {
  margin: 0 !important;
  width: 100% !important;
}
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding-right: 2%;
  padding-left: 2%;
}
.cb-mobile-filter-items li {
  display: inline-block;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid {
  position: relative;
  overflow: hidden;
  padding-right: 4%;
  padding-left: 4%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 4em;
  height: 100%;
  /*background-color: #1e1e1e;*/
  background-image: -webkit-gradient(linear, left center, right center, from(rgba(30, 30, 30, 0)), to(#1e1e1e));
  background-image: -webkit-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -moz-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -o-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -ms-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  content: '';
}
.mese tbody th,
.mese td > .cb-hybrid,
.mese td > span {
  padding-right: 0.4em;
  padding-left: 0.4em;
}
.cb-mobile-filter-items > .beta {
  text-align: center;
}
.cb-mobile-filter-items > .beta > span {
  float: left;
  padding: 0;
  width: 20%;
  line-height: 3em;
}
.cb-mobile-filter-items > .beta > span > .cb-hybrid {
  padding: 0;
}
.cb-mobile-filter-items > .scan > p > span > .cb-hybrid {
  display: inline-block;
  overflow: hidden;
  max-width: 9em;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.font9 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font12 {
  font-size: 16px;
  font-size: 1.6rem;
}
.font15 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font18 {
  font-size: 25px;
  font-size: 2.5rem;
}
.font21 {
  font-size: 29px;
  font-size: 2.9rem;
}
.font24 {
  font-size: 33px;
  font-size: 3.3rem;
}
.font27 {
  font-size: 37px;
  font-size: 3.7rem;
}
.font30 {
  font-size: 42px;
  font-size: 4.2rem;
}
.font32 {
  font-size: 44px;
  font-size: 4.4rem;
}
.cb-mobile-filter-items .cb-count,
.cb-mobile-filter-items .days td > span,
.cb-mobile-filter-items .week td > span,
.cb-mobile-filter-items .year td > span {
  display: none;
}
.cb-mobile-filter-items .same > span:first-child {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.keys input {
  display: table;
  margin: 0.3em auto;
  width: 86%;
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.keys input + .cb-hybrid {
  display: none;
}
.scan select {
  margin: 0.6em 2%;
  width: 96%;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #272727;
  color: #e6e6e6;
  text-decoration: none;
}
/******* module-forum.less 2013-1-16 *******/
.resp > .foot {
  margin: 0 !important;
}
.resp > .foot > .scan > .ctrl {
  margin-top: 1em;
  text-align: right;
}
/* HEAD */
.frum > .head {
  position: relative;
}
.frum .rss > a {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  position: absolute;
  top: 6px;
  right: 8px;
  width: 12px;
  height: 12px;
  background: url(/icon-link/rss.gif) no-repeat;
}
div.frum div.resp span.topi {
  display: block;
  text-align: right;
}
.resp > .link {
  background-image: none;
}
.resp > .link > .open {
  margin: 0;
}
div.frum div.resp div.link span {
  margin-left: 5px;
}
div.frum div.topi {
  padding: 0.3em 0;
  border-bottom: 1px solid #bbb;
  background-color: #efefef;
}
div.frum div.topi p,
div.frum div.topi div.link {
  margin: 0 0.5em;
}
/******* 1ST ENTRY *******/
div.frum div.pend,
div.dlog div.pend {
  color: #bbb;
}
.resp .swap > .ctrl > .cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #4c4c4c;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  line-height: 1.3em;
}
.resp .swap > .ctrl > .cb-hybrid:hover,
.resp .swap > .ctrl > .cb-hybrid:focus {
  border-color: #b3b3b3 #999999 #999999 #b3b3b3;
  background-color: #d4d4d4;
  color: #3d3d3d;
  text-decoration: none;
  cursor: pointer;
}
.resp .swap > .ctrl > .cb-hybrid:active {
  outline: none;
  border-color: #8c8c8c #a6a6a6 #a6a6a6 #8c8c8c;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  text-shadow: none;
}
div.frum div.ctrl a.back,
div.frum div.ctrl a.resp {
  float: left;
  padding: 1px 0;
  width: 40%;
}
div.frum div.ctrl a.resp {
  float: right;
}
#edit div.frum div.ctrl a.fade,
#edit div.dlog div.ctrl a.fade {
  border: 1px solid #ccc;
  background-color: #eee;
  color: #808080;
}
.resp > .link > .cb-hybrid {
  white-space: nowrap;
}
#root #lbox div.onse {
  left: 50%;
  margin-left: -225px;
  width: 450px;
}
#root #lbox div.onse div.body div.resp {
  overflow: auto;
  max-height: 350px;
}
#root #lbox div.onse div.foot p {
  margin-bottom: 12px;
}
#root #lbox div.onse div.wait {
  float: left;
  margin-bottom: 8px;
  width: 413px;
  border-bottom: 2px solid #dedede;
}
#root #lbox div.onse div.wait div.resp {
  float: left;
  width: 68%;
}
#root #lbox div.onse div.link {
  background: none;
}
#root #lbox div.onse div.link a {
  float: left;
  margin-left: 0;
}
#root #lbox div.onse div.link p {
  float: right;
}
#root #lbox div.onse div.body div.ctrl {
  margin-left: 70%;
}
#root #lbox div.onse div.body div.ctrl input.radio {
  float: left;
  margin: 0 5px;
}
#root #lbox div.onse div.body div.ctrl label {
  float: left;
}
#root #lbox div.onse div.body div.ctrl div {
  float: left;
  width: 124px;
}
/******* module-mayoris.less 2013-1-16 *******/
#disp.mayo {
  left: 50%;
  margin-left: -260px;
  width: 520px;
}
#frmSubsc_container {
  float: left;
  width: 500px;
}
#frmSubsc {
  float: left;
  width: 300px;
}
div.side-hint {
  display: block !important;
  float: left;
  margin-left: 10px;
  width: 190px;
}
div.side-hint h3 {
  margin-bottom: 0.5em;
  padding: 0 5px;
  border-bottom: 1px solid #bbb;
  background-color: #eee;
}
div.side-hint p {
  margin-bottom: 1em;
  padding: 0 5px;
}
#disp.mayo div.foot {
  clear: both;
}
#disp.mayo input {
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
}
#disp.mayo ul li.mandatory label {
  font-weight: bold;
}
#disp.mayo #SpryGent label.radio {
  font-weight: normal;
}
#disp.mayo ul {
  list-style-type: none;
}
#disp.mayo ul li {
  float: left;
  margin-bottom: 5px;
  width: 300px;
}
div.mayo div.error-message {
  margin-bottom: 10px;
  border: 4px solid #e64246;
  border-style: none solid;
  color: #e64246;
  text-align: center;
  letter-spacing: 0.025em;
}
div.mayo #SpryThm ul li {
  padding: 5px 0;
  border: 1px solid #ccc;
  border-style: solid none;
  background-color: #efefef;
}
div.mayo #SpryThm ul li:hover {
  border-color: #888;
}
#disp.mayo #SpryThm ul li input.checkbox {
  float: left;
  margin: 1px 0 0 15px;
}
#disp.mayo ul li ul.list-vertical label.checkbox,
#disp.mayo ul li ul.list-vertical a.preview {
  display: block;
  margin-left: 50px;
}
#disp.mayo ul li ul.list-vertical a.preview {
  padding-left: 20px;
  background: transparent url(/icons/eye.png) no-repeat 0 50%;
}
.checkboxRequiredMsg,
.radioRequiredMsg,
.textfieldRequiredMsg,
.textfieldRequiredMsg,
.textfieldInvalidFormatMsg {
  display: none;
}
#SpryGent ul {
  float: left;
  width: 300px;
}
#SpryGent ul li {
  float: left;
  margin: 0.15em 20px 0 0;
  width: auto;
}
#disp.mayo ul li select {
  display: block;
  margin-bottom: 0;
  padding: 1px 0;
  width: 300px;
  border: 1px solid #808080;
  border-bottom-color: #ccc;
  font-size: inherit;
}
#disp.mayo ul li label.zip {
  float: left;
}
#disp.mayo ul li label.city {
  display: block;
}
#disp.mayo ul li input.size-l,
#disp.mayo ul li input.size-xs,
#disp.mayo ul li input.size-m {
  display: block;
  padding: 1px 0;
  border: 1px solid #808080;
  border-bottom-color: #ccc;
  background: #fff url(/icons/formbg.png) repeat-x 0 0;
  font-size: inherit;
}
#disp.mayo ul li input.size-l:focus,
#disp.mayo ul li input.size-xs:focus,
#disp.mayo ul li input.size-m:focus,
#disp.mayo ul li select:focus {
  outline: none;
  border-color: #99b5de;
}
#disp.mayo ul li input.size-l {
  width: 298px;
}
#disp.mayo ul li input.size-xs {
  float: left;
  margin-right: 5px;
  width: 65px;
}
#disp.mayo ul li input.size-m {
  float: left;
  width: 226px;
}
#disp.mayo p.buttons {
  float: left;
  margin-top: 15px;
  width: 300px;
}
#disp.mayo p.buttons input.save {
  overflow: visible;
  padding: 1px 0;
  width: 100%;
  border: 1px outset #ccc;
  background-color: #B66695;
  color: #fff;
  letter-spacing: 0.025em;
  font-size: inherit;
  cursor: pointer;
}
#disp.mayo p.buttons input.save:hover {
  border-color: #333;
}
#disp.mayo p.buttons input.save:active {
  border-style: inset;
  background-color: #9a557d;
}
#root #disp.mayo ul li.textfieldRequiredState input,
#root #disp.mayo ul li.textfieldInvalidFormatState input,
#lbox li.radioRequiredState ul {
  border-color: #e64246;
  background: #fff url(/icons/warning.png) no-repeat 100% 50%;
  color: #e64246;
}
#disp.mayo ul li.checkboxRequiredState ul li {
  border-color: #e64246;
  background-color: #fff;
}
/******* module-security.less 2013-1-16 *******/
.cb-part-foot {
  clear: both;
  margin: 0 !important;
}
.cb-part-body {
  /* only used in authentication part */
  margin: 0.4em 0;
}
.auth fieldset {
  padding: 0.2em 0;
}
.auth .text,
.auth .password {
  float: right;
  width: 100%;
}
.auth .ctrl .sign {
  display: block;
  margin-left: 20px;
  line-height: 19px;
}
/******* module-shop.less 2013-1-16 *******/
input.prix {
  float: left;
  margin-right: 2px;
  width: 45px;
}
.cury {
  /* was div.payp span.cury, check shop lightbox */
  margin-left: 4px;
}
.payp input.numb {
  font-size: 16px;
  font-size: 1.6rem;
  float: left;
  margin-right: 5px;
  width: 2em !important;
  text-align: right;
}
.payp .ctrl > div,
.ctrl > .payp {
  display: inline-block;
  vertical-align: middle;
}
.ctrl > .payp {
  top: 0.1em;
  position: relative;
  padding: 0 0.5em;
}
.payp .name,
.payp .ctrl {
  padding-bottom: 0.5em;
}
.payp .name {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.payp.tile,
.payp.tile .papc {
  position: relative;
}
.payp.tile .papc {
  padding-bottom: 2em;
  margin-bottom: -2em;
}
.payp.tile input.numb {
  bottom: 0.4em;
  position: absolute;
}
.payp .ctrl {
  float: right;
  text-align: right;
}
.payp input.payp {
  padding: 0;
  width: 25px;
  height: 25px;
  border: 0;
  background: url(/icons/cart.png) no-repeat;
}
.payp input.payp:hover {
  background-position: 0px -30px;
  background-color: transparent;
}
.payp input.success {
  background-position: 0px -60px;
}
.payp input.success:hover {
  background-position: 0px -90px;
}
.payp input.fade,
.payp input.fade:hover {
  background-position: 0px -120px;
}
#root div.payp textarea {
  resize: none;
}
.cb-price-comment,
.cb-price-file,
.cb-price-file input {
  float: left;
  margin-top: 0.2em;
  width: 100%;
}
.cb-price-comment > textarea {
  width: 100%;
}
.cb-price-comment > .hint {
  color: #8c8c8c;
}
#disp.shop .name,
a.agbs,
.cb-shoppingcart .cb-shop-country > label {
  float: left;
  padding-right: 1%;
}
.cb-shop-country > label {
  width: 31%;
}
#disp .cb-shop-country > select {
  width: 68%;
}
a.agbs {
  margin-bottom: 5px;
  background: url(/icon-small/pdf.gif) no-repeat 2px 4px;
  text-indent: 15px;
}
a.agbs + select + a {
  margin-top: 2px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 18px;
  height: 16px;
  margin-top: 0.13em;
  background: url(/icons/cross.png) no-repeat 0 0;
}
a.agbs + select + a:hover,
a.agbs + select + a:focus {
  background-position: 0 -22px;
}
a.agbs + select + a:active {
  background-position: 0 -47px;
}
.cb-agb > fieldset {
  float: left;
  margin-bottom: 10px;
}
.cb-shop-tab-contents > h3,
.cb-shoppingcart h3 {
  clear: both;
  margin: 14px 0 5px;
  padding: 0.2em 5px;
  border-bottom: 1px solid #aaa;
  background-color: #f5f5f5;
  color: #333;
  letter-spacing: 0.02em;
  font-size: 1.1em;
  line-height: 1.25;
}
#disp.shop .foot {
  padding: 10px 0;
  border-top: 1px solid #aaa;
}
.cb-shop-tabs {
  margin: 10px 0 -14px;
  border-bottom: 1px solid #aaa;
}
.cb-shop-tabs > li {
  display: block;
}
.cb-shop-tab {
  display: block;
  padding: 0.3em 0.4em;
  border: 1px solid #aaa;
  border-bottom-width: 0;
  background: #ddd;
  text-decoration: none;
}
.cb-shop-tab.selected {
  background: #f5f5f5;
}
.cb-shop-tab-contents > .cb-shop-tab-contents {
  display: none;
}
.cb-shop-tab-contents > .selected {
  display: block;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  min-height: 1px;
}
#disp.shop .name,
a.agbs {
  width: 59%;
}
.shop .file,
.cb-shoppingcart .file,
.shop select,
.cb-shoppingcart select {
  float: left;
}
.cb-shop-exchange-rate-equal {
  float: left;
}
#disp.shop .cb-shop-exchange-rate > .name {
  padding-right: 0.5em;
  width: inherit;
}
.cb-shop-exchange-rate {
  margin-bottom: 8px;
}
.cb-shop-currency {
  position: relative;
}
.cb-shop-currency > select + a {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 18px;
  height: 16px;
  margin-top: 0.13em;
  background: url(/icons/cross.png) no-repeat 0 0;
  float: left;
  margin-top: 0.1em;
}
.cb-shop-currency > select + a:hover,
.cb-shop-currency > select + a:focus {
  background-position: 0 -22px;
}
.cb-shop-currency > select + a:active {
  background-position: 0 -47px;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  display: inline-block;
  text-decoration: underline;
}
.cb-shop-vat > .cury {
  float: left;
}
#disp.shop .zip {
  float: left;
  clear: left;
  margin-right: 1%;
  width: 18%;
  text-align: right;
}
#disp.shop input.city,
#disp.shop .cb-shop-currency > select {
  width: 81%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: left;
}
#cb-footnotes {
  margin: 10px 0;
  color: #808080;
  text-align: center;
}
.cb-shoppingcart table {
  border-collapse: collapse;
}
.cb-shoppingcart th,
.cb-shoppingcart td {
  padding: 4px 3px;
}
.cb-shoppingcart th {
  border-bottom: 2px solid #666;
  color: #222;
  text-align: left;
}
.cb-shoppingcart .hdpr {
  text-align: right;
}
#items > .odd {
  background: none;
}
.cb-shoppingcart td {
  border-bottom: 1px solid #aaa;
  vertical-align: middle;
}
.cb-total-without-vat > td,
.cb-total-with-vat > td {
  border: 2px solid #666;
  border-style: solid none;
  background-color: #f5f5f5;
  color: #222;
  font-weight: bold;
}
.cost {
  text-align: right;
}
.remo,
.cb-shop-vat > .cury + a {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 18px;
  height: 16px;
  margin-top: 0.13em;
  background: url(/icons/cross.png) no-repeat 0 0;
}
.remo:hover,
.cb-shop-vat > .cury + a:hover,
.remo:focus,
.cb-shop-vat > .cury + a:focus {
  background-position: 0 -22px;
}
.remo:active,
.cb-shop-vat > .cury + a:active {
  background-position: 0 -47px;
}
div.cb-payment .radio {
  margin-right: 4px;
}
div.cb-payment div.fail {
  float: none;
}
div.cb-shoppingcart div.ship {
  margin-right: 0;
}
#disp .cb-additional-information textarea {
  overflow: scroll;
  /* module shop */
  height: auto;
}
#disp fieldset.last {
  margin: 0;
}
.cb-address,
.cb-additional-information,
.cb-payment,
.cb-order,
.cb-sandbox,
.cb-agb,
.cb-shop-country {
  float: left;
  width: 100%;
}
.cb-shoppingcart .cb-shipping-address-option {
  clear: both;
  margin: 10px 0;
  padding: 2px 0;
  border: 1px solid #808080;
  background-color: #ddd;
  color: #222;
  font-weight: bold;
}
div.cb-shoppingcart div.cb-address p.cb-notice {
  margin-top: 7px;
  color: #808080;
}
.cb-address > a {
  float: left;
  clear: left;
  margin: 20px 0;
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #4c4c4c;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  line-height: 1.3em;
}
.cb-address > a:hover,
.cb-address > a:focus {
  border-color: #b3b3b3 #999999 #999999 #b3b3b3;
  background-color: #d4d4d4;
  color: #3d3d3d;
  text-decoration: none;
  cursor: pointer;
}
.cb-address > a:active {
  outline: none;
  border-color: #8c8c8c #a6a6a6 #a6a6a6 #8c8c8c;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  text-shadow: none;
}
#disp.cb-shoppingcart input.numb {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0;
  width: 30px;
  text-align: right;
}
div.cb-shoppingcart a.agbs {
  margin-right: 10px;
}
.cb-shoppingcart fieldset > .fail {
  /* overwrites div.fail */
  float: none;
}
div.cb-shoppingcart input.cb-hybrid {
  margin-left: 5px;
}
div.cb-payment input.cb-hybrid {
  margin: 7px 0 0 0;
}
#disp.shop input.conf,
.cb-shoppingcart .next {
  float: right;
}
.cb-shoppingcart .next {
  font-weight: bold;
}
.foot > fieldset > form {
  float: left;
}
.foot > fieldset > form + form {
  float: right;
}
label.tiny,
label.tall {
  font-weight: bold;
}
#disp label.tiny {
  float: left;
  width: 20%;
}
#disp p.tiny {
  float: left;
  width: 80%;
}
#disp select.tiny {
  float: left;
  width: 80px !important;
}
#disp.shop .cb-shop-exchange-rate > .tiny {
  float: left;
  margin: 0 5px 0 10px;
  width: 4em !important;
  text-align: right;
}
#disp .tiny > label.name {
  width: 100%;
}
#disp.shop input.tiny {
  float: left;
  width: 5em;
  text-align: right;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: 1px solid #ddd;
  border-style: solid none none;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/*# sourceMappingURL=./screen-small.css.map */